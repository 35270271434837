const version = "v3";

const config = {
   urls: {
      coachUpdate: `/api/${version}/coach/update`,
      createInterview: `/api/${version}/interview`,
      generator: `/api/${version}/generate`,
      getSpeechToken: `/api/${version}/get-speech-token`,
      history: `/api/${version}/history`,
      preparedness: `/api/${version}/preparedness`,
      preparednessChat: `/api/${version}/preparedness/chat`,
      preparednessPopup: `/${version}/interview`,
      preparednessStart: `/api/${version}/preparedness/video/start`,
      preparednessTips: `/api/${version}/preparedness/tips`,
      preparednessVideo: `/api/${version}/preparedness/video`
   }
};

export default config;
