import React from "react";
import { ProgressBar } from "react-bootstrap";

/**
 * @param {object} props
 * @param {boolean} [props.inactive]
 * @param {number} [props.score]
 */
function PerformanceBar(props) {
   return (
      <div className="d-flex my-1 gap-3 align-items-center">
         {props.inactive ? (
            <ProgressBar now={50} variant="dark" className="flex-grow-1 my-3" />
         ) : (
            <ProgressBar
               now={props.score || 100}
               label={!props.score ? "Analyzing" : undefined}
               animated={!props.score}
               variant="info"
               className="flex-grow-1 my-3"
            />
         )}
         {props.score && !props.inactive ? (
            <div className="coach-score-percentage">{props.score.toFixed(0)}%</div>
         ) : null}
      </div>
   );
}

export default PerformanceBar;
