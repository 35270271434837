import axios from "axios";
import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import config from "../config";
import useUserParam from "../hooks/useUserParam";

function PrepareButton({ history }) {
   const user = useUserParam();

   const [preparing, setPreparing] = useState(history.preparedness.length > 0);
   const [finished, setFinished] = useState(history.preparedness.length > 0);

   return (
      <Button
         className="flex-grow-1 list-button"
         variant={finished ? "secondary" : "outline-secondary"}
         onClick={
            !finished
               ? () => {
                    setPreparing(true);
                    axios
                       .post(config.urls.preparedness, {
                          user,
                          history: history.id
                       })
                       .then(res => {
                          console.log(res.data);
                          setFinished(true);
                       });
                 }
               : () => {
                    const redirectUrl =
                       process.env.REACT_APP_OWN_URL + process.env.REACT_APP_QUESTIONS_REDIRECT;
                    window.top.location.href = redirectUrl;
                 }
         }
         disabled={preparing && !finished}
      >
         {finished ? (
            "View Interview Questions"
         ) : preparing ? (
            <>
               Creating <Spinner size="sm" />
            </>
         ) : (
            "Get Interview Questions"
         )}
      </Button>
   );
}

export default PrepareButton;
