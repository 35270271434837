import React, { useEffect, useCallback } from "react";
import { useState } from "react";
import axios from "axios";
import { Spinner, Button, Badge } from "react-bootstrap";
import RichTextEditor from "react-rte";
import ResultArea from "../components/ResultArea";
import useUserParam from "../hooks/useUserParam";
import config from "../config";
import useLanguageParam from "../hooks/useLanguageParam";

const CHARACTER_LIMIT = 14000;
const WARNING_THRESHOLD = 1500;

const messages = [
   "Analyzing",
   "Processing",
   "Generating",
   "Crafting",
   "Testing",
   "Applying",
   "Organizing",
   "Validating",
   "Documenting",
   "Constructing",
   "Querying",
   "Executing",
   "Implementing",
   "Surveying",
   "Compiling",
   "Reviewing",
   "Finalizing"
];

function Generator() {
   const user = useUserParam();
   const lang = useLanguageParam();
   const [prompt, setPrompt] = useState(RichTextEditor.createEmptyValue());
   const [cleanPrompt, setCleanPrompt] = useState("");
   const [cvResult, setCvResult] = useState("");
   const [letterResult, setLetterResult] = useState("");
   const [showTimeoutMessage, setShowTimeoutMessage] = useState(false);
   const [working, setWorking] = useState(false);
   const [badgeColors, setBadgeColors] = useState({
      bg: "light",
      text: "dark"
   });

   const [msgi, setMsgi] = useState(0);
   useEffect(() => {
      if (working) {
         const msgiTimerId = setInterval(
            () => setMsgi(i => Math.min(i + 1, messages.length - 1)),
            3500
         );
         return () => clearInterval(msgiTimerId);
      } else setMsgi(0);
   }, [working]);

   useEffect(() => {
      if (working && msgi === messages.length - 1) {
         const timeoutTimerId = setTimeout(() => setShowTimeoutMessage(true), 30 * 1000);
         return () => clearTimeout(timeoutTimerId);
      } else setShowTimeoutMessage(false);
   }, [working, msgi]);

   useEffect(() => {
      if (cleanPrompt.length > CHARACTER_LIMIT) setBadgeColors({ bg: "danger", text: "light" });
      else if (cleanPrompt.length > CHARACTER_LIMIT - WARNING_THRESHOLD)
         setBadgeColors({ bg: "warning", text: "dark" });
      else setBadgeColors({ bg: "light", text: "dark" });
   }, [cleanPrompt]);

   const cleanup = useCallback(
      str =>
         str
            .trim()
            // The input text area seems to love sprinkling in zero-width spaces
            .replace(/\u200b/g, "")
            // Lines with nothing but whitespace can have that whitespace trimmed
            // This also matches multiple newlines in a row
            .replace(/^\s+$/gm, ""),
      []
   );
   const generateBoth = useCallback((prompt, language, user) => {
      setWorking(true);
      axios
         .post(config.urls.generator, {
            language,
            prompt,
            user
         })
         .then(
            res => {
               setLetterResult(res.data.letter);
               setCvResult(res.data.resume);
            },
            err => {
               console.warn("No response");
               console.error(err);
               const statusType = (err.response.status / 100) | 0;
               if (err.response.status === 403) {
                  setLetterResult(err.response.data);
                  setCvResult(err.response.data);
               } else if (statusType === 4) {
                  setLetterResult("Unable to authenticate with generator server.");
                  setCvResult("");
               } else {
                  setLetterResult(
                     "We apologize, our servers are overloaded with requests. Please try again in a few minutes."
                  );
                  setCvResult("");
               }
            }
         )
         .then(() => setWorking(false));
   }, []);

   const [letterSpinner, setLetterSpinner] = useState(false);
   const regenLetter = useCallback((prompt, language, user) => {
      setLetterSpinner(true);
      axios
         .get(config.urls.generator, {
            params: { prompt, language, user }
         })
         .then(
            res => setLetterResult(res.data.letter),
            err => {
               console.warn("No response");
               console.error(err);
               const statusType = (err.response.status / 100) | 0;
               if (statusType === 4)
                  setLetterResult("Unable to authenticate with generator server.");
               else
                  setLetterResult(
                     "We apologize, our servers are overloaded with requests. Please try again in a few minutes."
                  );
            }
         )
         .then(() => setLetterSpinner(false));
   }, []);

   const [resumeSpinner, setResumeSpinner] = useState(false);
   const regenResume = useCallback((prompt, language, user) => {
      setResumeSpinner(true);
      axios
         .get(config.urls.generator, {
            params: { prompt, language, user }
         })
         .then(
            res => setCvResult(res.data.resume),
            err => {
               console.warn("No response");
               console.error(err);
               const statusType = (err.response.status / 100) | 0;
               if (statusType === 4) setCvResult("Unable to authenticate with generator server.");
               else
                  setCvResult(
                     "We apologize, our servers are overloaded with requests. Please try again in a few minutes."
                  );
            }
         );
   }, []);

   return (
      <div className="App">
         <div className="d-flex align-items-end justify-content-between" style={{ gap: "0.5rem" }}>
            <p className="generator-heading">Paste Job Details:</p>
            <div className="ml-auto mb-1 d-flex flex-wrap" style={{ gap: "0.25rem" }}>
               {/* <Button size="sm" onClick={() => setLang(l => (l + 1) % languages.length)}>
                  {languages[lang]}
               </Button> */}
               <Button
                  size="sm"
                  variant="warning"
                  onClick={() => {
                     setPrompt(RichTextEditor.createEmptyValue());
                     setLetterResult("");
                     setCvResult("");
                  }}
               >
                  Clear All
               </Button>
            </div>
         </div>
         <div style={{ position: "relative", flex: 2, overflowY: "auto" }}>
            <RichTextEditor
               rootStyle={{ height: "100%", overflowY: "auto", fontFamily: "sans-serif" }}
               toolbarStyle={{ display: "none" }}
               value={prompt}
               onChange={val => {
                  setPrompt(val);
                  setCleanPrompt(cleanup(val.toString("markdown")));
               }}
               editorStyle={{ height: "100%" }}
            />
            <Badge
               pill
               {...badgeColors}
               className="bottom-right-position"
               style={{
                  fontSize: "0.66rem"
               }}
            >
               {cleanPrompt.length} / {CHARACTER_LIMIT}
            </Badge>
         </div>
         <div className="mx-auto my-3 px-2 text-center">
            <Button
               variant="primary"
               disabled={working || cleanPrompt.length < 10 || cleanPrompt.length > CHARACTER_LIMIT}
               onClick={() => generateBoth(cleanPrompt, lang, user)}
            >
               {!working ? (
                  "Create Resume and Cover Letter"
               ) : (
                  <>
                     {messages[msgi]} <Spinner size="sm" />
                  </>
               )}
            </Button>
            {showTimeoutMessage ? (
               <div className="mt-2">
                  This is taking longer than expected. You can continue waiting or check history
                  later.
               </div>
            ) : null}
         </div>
         <ResultArea
            label="Cover Letter:"
            value={letterResult}
            regenerate={regenLetter}
            spinner={letterSpinner}
            //docxMeta={{}}
         />
         <ResultArea
            className="mt-4"
            label="Resume:"
            value={cvResult}
            regenerate={regenResume}
            spinner={resumeSpinner}
            //docxMeta={{}}

         />
         <div className="mx-auto my-3 px-2">
            <Button
               variant="primary"
               disabled={working || cleanPrompt.length < 10 || cleanPrompt.length > CHARACTER_LIMIT}
               onClick={() => generateBoth(cleanPrompt, lang, user)}
            >
               Create Another
            </Button>
         </div>
      </div>
   );
}

export default Generator;
