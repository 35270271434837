import React, { useState } from "react";

function HistoryDetails({ generation }) {
   const [hidePosting, setHidePosting] = useState(true);
   const [hideLetter, setHideLetter] = useState(false);
   const [hideResume, setHideResume] = useState(false);

   return (
      <div className="table-expand-area-item">
         <div>
            <strong>Job Posting</strong>
            &emsp;
            <small role="button" onClick={() => setHidePosting(h => !h)}>
               {hidePosting ? "(View)" : "(Hide)"}
            </small>
         </div>
         <p hidden={hidePosting} className="text-section">
            {generation.posting}
         </p>
         <hr />
         <div>
            <strong>Cover Letter</strong>
            &emsp;
            <small role="button" onClick={() => setHideLetter(h => !h)}>
               {hideLetter ? "(View)" : "(Hide)"}
            </small>
         </div>
         <p hidden={hideLetter} className="text-section">
            {generation.generatedCoverLetter}
         </p>
         <hr />
         <div>
            <strong>Resume</strong>
            &emsp;
            <small role="button" onClick={() => setHideResume(h => !h)}>
               {hideResume ? "(View)" : "(Hide)"}
            </small>
         </div>
         <p hidden={hideResume} className="text-section">
            {generation.generatedResume}
         </p>
      </div>
   );
}

export default HistoryDetails;
