import React from "react";
import Generator from "./pages/generator";
import History from "./pages/history";
import Preparedness from "./pages/preparedness";
import PreparednessGenerator from "./pages/preparedness-generator";

const v2Routes = [
   { path: "generator", element: <Generator /> },
   { path: "history", element: <History /> },
   { path: "preparedness-generator", element: <PreparednessGenerator /> },
   { path: "preparedness", element: <Preparedness /> }
];

export default v2Routes;
