import React from "react";
import "./App.scss";
import Generator2 from "./pages/generator2";
import { createBrowserRouter, redirect, RouterProvider } from "react-router-dom";
import v1Routes from "./v1";
import versions from "./versions";

function App() {
   const router = createBrowserRouter([
      { path: "/", loader: () => redirect("/v1/generator") },
      { path: "/new-generator", element: <Generator2 /> },
      { path: "/v1", children: v1Routes },
      ...versions
   ]);

   return <RouterProvider router={router} />;
}

export default App;
