import React, { useEffect, useState } from "react";
import axios from "axios";
import useUserParam from "../hooks/useUserParam";
import { Table } from "react-bootstrap";

function History() {
   const user = useUserParam();

   const [historyData, setHistoryData] = useState([]);
   const [expanded, setExpanded] = useState();

   useEffect(() => {
      axios
         .get("/api/v1/history", {
            params: {
               user
            }
         })
         .then(
            res => {
               console.log(res.data);
               setHistoryData(res.data);
            },
            err => {
               console.warn("Failed to get history");
            }
         );
   }, [user]);

   return (
      <Table className="w-100 h-100" borderless>
         <thead>
            <tr>
               <th>#</th>
               <th>Company</th>
               <th>Job Title</th>
               <th>Time</th>
               <th></th>
            </tr>
         </thead>
         <tbody>
            {historyData.map(row => {
               const created = new Date(row.created);
               return (
                  <React.Fragment key={row.id}>
                     <tr style={{ borderTop: "2px solid whitesmoke" }}>
                        <td data-col="ID#">{row.id}</td>
                        <td data-col="Company">{row.company || "\u00a0"}</td>
                        <td data-col="Job Title">{row.jobTitle || "\u00a0"}</td>
                        <td data-col="Time">
                           {created.toLocaleString("default", {
                              day: "numeric",
                              month: "numeric",
                              year: "numeric",
                              hour: "numeric",
                              minute: "2-digit"
                           })}
                        </td>
                        {/* <td>
                           {created.toLocaleTimeString("default", {
                              hour: "numeric",
                              minute: "2-digit"
                           })}
                        </td> */}
                        {row.id !== expanded ? (
                           <td
                              className="ico-exp"
                              role="button"
                              onClick={() => setExpanded(row.id)}
                           >
                              Expand
                           </td>
                        ) : (
                           <td className="ico-col" role="button" onClick={() => setExpanded()}>
                              Collapse
                           </td>
                        )}
                     </tr>
                     <tr hidden={row.id !== expanded}>
                        <td colSpan={6} className="p-3">
                           <p style={{ whiteSpace: "pre-wrap" }}>{row.generatedCoverLetter}</p>
                           <hr />
                           <p style={{ whiteSpace: "pre-wrap" }}>{row.generatedResume}</p>
                        </td>
                     </tr>
                  </React.Fragment>
               );
            })}
         </tbody>
      </Table>
   );
}

export default History;
