import React, { useEffect, useState } from "react";
import useUserParam from "../hooks/useUserParam";
import axios from "axios";
import config from "../config";
import { Button, Table } from "react-bootstrap";
import PreparednessArea from "../components/PreparednessArea";

function Preparedness() {
   const user = useUserParam();

   const [historyData, setHistoryData] = useState([]);
   const [expanded, setExpanded] = useState();

   useEffect(() => {
      axios.get(config.urls.preparedness, { params: { user } }).then(
         res => {
            const data = res.data.sort((a, b) => new Date(b.created) - new Date(a.created));
            console.log(data);
            setHistoryData(data);
         },
         err => {
            console.warn("Failed to get preparedness history");
            console.error(err);
         }
      );
   }, [user]);

   return (
      <Table className="w-100 h-100" borderless>
         <colgroup>
            <col
               span="1"
               style={{
                  width: "4%"
               }}
            />
            <col
               span="2"
               style={{
                  width: "30%"
               }}
            />
            <col
               span="1"
               style={{
                  width: "16%"
               }}
            />
            <col
               span="1"
               style={{
                  width: "16%"
               }}
            />
         </colgroup>
         <thead>
            <tr>
               <th>#</th>
               <th>Company</th>
               <th>Job Title</th>
               <th>Date</th>
               <th></th>
            </tr>
         </thead>
         <tbody>
            {historyData.map(row => {
               const created = new Date(row.created);
               return (
                  <React.Fragment key={row.id}>
                     <tr style={{ borderTop: "2px solid whitesmoke" }}>
                        <td data-col="ID#">{row.id}</td>
                        <td data-col="Company">{row.company || "\u00a0"}</td>
                        <td data-col="Job Title">{row.jobTitle || "\u00a0"}</td>
                        <td data-col="Time">
                           {created.toLocaleDateString("default", {
                              day: "numeric",
                              month: "numeric",
                              year: "numeric"
                           })}
                           <br />
                           {created.toLocaleTimeString("default", {
                              hour: "numeric",
                              minute: "2-digit"
                           })}
                        </td>
                        <td>
                           <Button
                              variant="success"
                              size="sm"
                              onClick={() => setExpanded(e => (e !== row.id ? row.id : null))}
                           >
                              {expanded !== row.id ? "Show" : "Hide"}
                           </Button>
                           {/* {row.id !== expanded ? (
                              <ChevronDown role="button" onClick={() => setExpanded(row.id)} />
                           ) : (
                              <ChevronUp role="button" onClick={() => setExpanded()} />
                           )} */}
                        </td>
                        <td></td>
                     </tr>
                     <tr hidden={row.id !== expanded}>
                        <td></td>
                        <td colSpan={4} className="pb-4">
                           <PreparednessArea data={row} />
                        </td>
                        <td></td>
                     </tr>
                  </React.Fragment>
               );
            })}
         </tbody>
      </Table>
   );
}

export default Preparedness;
